.ui.menu {
  border: 1px solid rgba(230, 230, 230, 0.6);
}

.visualizer {
  width: 250px;
  height: 150px;
}

.CalendarDay__firstDayOfWeek {
  color: #e10600 !important;
}

.CalendarDay {
  color: #707070;
}

.CalendarDay__default {
  border: 1px solid rgba(209, 211, 212, 1) !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: rgba(209, 211, 212, 0.5) !important;
  border: 1px solid rgba(209, 211, 212, 1) !important;
  color: #0894d7;
  font-weight: 600;
}

@import 'font.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../../node_modules/leaflet/dist/leaflet.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

html,
body {
  /* 모바일 pinch zoom 방지 */
  touch-action: pan-y;
  -webkit-user-drag: none;
  /* font-size: 10px; */
}
html:lang(ko) {
  font-family: 'Nanum Gothic', 'Noto Sans SC', 'Comfortaa', sans-serif !important;
  word-break: keep-all;
}
html:lang(en) {
  font-family: 'Noto Sans SC', 'Comfortaa', 'Nanum Gothic', sans-serif !important;
}

body {
  font-family: 'Noto Sans SC', 'Comfortaa', 'Nanum Gothic', sans-serif !important;
  /* font-size: 10px; */
  color: #424848;
  background-color: white;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
}
body:lang(ko) {
  font-family: 'Nanum Gothic', 'Noto Sans SC', 'Comfortaa', sans-serif !important;
  word-break: keep-all;
}
body:lang(en) {
  font-family: 'Noto Sans SC', 'Comfortaa', 'Nanum Gothic', sans-serif !important;
}

textarea,
select,
input {
  /* font-size: 10px; */
  color: #424848;
  background-color: white;
  outline: none;
}
textarea:lang(ko),
select:lang(ko),
input:lang(ko),
button:lang(ko) {
  font-family: 'Nanum Gothic', 'Noto Sans SC', 'Comfortaa', sans-serif !important;
  word-break: keep-all;
}
textarea:lang(en),
select:lang(en),
input:lang(en),
button:lang(en) {
  font-family: 'Noto Sans SC', 'Comfortaa', 'Nanum Gothic', sans-serif !important;
}

/* 링크 스타일 */
a:link,
a:visited {
  color: #0896d9;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #0896d9;
}

.readable-hidden,
.a11y,
legend {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
}

.a11y:focus {
  top: 5px;
  left: 50%;
  width: auto;
  height: auto;
  margin: 0;
  clip: rect(auto, auto, auto, auto);
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  transform: translateX(-50%);
  /*자신의 크기 절반만큼 왼쪽으로 이동*/
  z-index: 10;
}

.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

div {
  touch-action: pan-x pan-y;
}

p:lang(ko),
span:lang(ko) {
  word-break: keep-all;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.red-text {
  color: #ef3340;
}
.orange-text {
  color: #f2711c;
}
.yellow-text {
  color: #ffb922;
}
.olive-text {
  color: #b5cc18;
}
.green-text {
  color: #21ba45;
}
.teal-text {
  color: #03bfd7;
}
.blue-text {
  color: #0894d7;
}
.violet-text {
  color: #6435c9;
}
.purple-text {
  color: #a333c8;
}
.pink-text {
  color: #e03997;
}
.brown-text {
  color: #a5673f;
}
.grey-text {
  color: #565b5b;
}
.black-text {
  color: #1b1c1d;
}

.link {
  /* text-decoration: underline;
  text-decoration-color: #0894d7; */
  text-decoration: underline;
  -webkit-text-decoration: underline;
}

/*.ui.dimmer {
  background-color: rgba(67, 73, 73, 0.8) !important;
}*/
.ui.dimmer > .content {
  color: #000 !important;
}
.slick-slider.slick-initialized {
  width: 100vw;
  height: auto;
}

/* .slick-list {
  padding: 0 40px !important;
} */

.slick-initialized .slick-slide {
  padding: 0 1.5rem;
}
.slick-slide img {
  width: calc(100% + 2rem);
  width: -moz-calc(100% + 2rem);
  width: -webkit-calc(100% + 2rem);
  height: auto;
}
.pushable {
  z-index: 10 !important;
}
i.icon.menu-back,
i.icon.pibo,
i.icon.bots,
i.icon.botstore {
  width: auto;
  height: auto;
  line-height: initial;
}
i.icon.menu-back::before {
  font-family: 'fontello';
  font-size: 1.18em;
  color: #565b5b;
  content: '\e801';
}
i.icon.pibo::before {
  font-family: 'fontello';
  font-size: 1.18em;
  content: '\e805';
}
i.icon.cclose::before {
  font-family: 'fontello';
  font-size: 1.18em;
  content: '\e807';
}
i.icon.bots::before {
  font-family: 'fontello';
  font-size: 1.18em;
  content: '\e800';
}
i.icon.botstore::before {
  font-family: 'fontello';
  font-size: 1.18em;
  content: '\e803';
}
i.icon.lte::before {
  content: 'LTE';
  font-size: 0.875em;
  font-weight: bold;
  text-shadow: -1px 0 #03bfd7 !important;
  letter-spacing: -0.5px;
}

@-webkit-keyframes colors {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes colors {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes colors {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

@media (max-width: 999px) {
  @keyframes jump {
    from {
      background-position: 0px 0px;
    }
    to {
      background-position-x: 0px;
      background-position-y: calc(37vw * -6);
    }
  }
}

@media (min-width: 1000px) {
  @keyframes jump {
    from {
      background-position: 0px 0px;
    }
    to {
      background-position-x: 0px;
      background-position-y: calc(370px * -6);
    }
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes loaderBlurring {
  0% {
    background-color: rgba(251, 251, 251, 0.85);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  100% {
    background-color: rgba(251, 251, 251, 0.2);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}

.animation.reveal.fadeIn {
  animation: fadeIn 350ms ease-in 150ms;
}
.animation.reveal.fadeOut {
  animation: fadeOut 500ms ease-out;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
.animation.reveal.delay {
  animation: fadeIn 500ms ease-out 500ms;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

div.externalWrap {
  margin: 0 0;
  padding: 0;
  height: calc(100vh - 60px);
  position: relative;
}
.externalIFrame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border: none;
}

select {
  /* for Firefox */
  /* -moz-appearance: none; */
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

@keyframes charging-1 {
  25%,
  50%,
  75%,
  100% {
    opacity: 1;
  }
}

@keyframes charging-2 {
  0% {
    opacity: 0;
  }
  50%,
  75%,
  100% {
    opacity: 1;
  }
}

@keyframes charging-3 {
  0%,
  25% {
    opacity: 0;
  }
  75%,
  100% {
    opacity: 1;
  }
}

@keyframes charging-4 {
  0%,
  25%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
Start the shake animation and make the animation last for 0.5 seconds
  animation: shake 0.5s;
When the animation is finished, start again
  animation-iteration-count: infinite;
 */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes little-shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-2px);
  }
  20% {
    transform: translateX(2px);
  }
  30% {
    transform: translateX(-2px);
  }
  40% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(2px);
  }
  70% {
    transform: translateX(-2px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake-animation {
  animation: little-shake 500ms linear 250ms 4;
}

q.bot_desc_q {
  color: rgba(66, 72, 72, 0.75) !important;
  font-size: 0.875em !important;
}

strong.bot_desc_strong {
  font-weight: 600 !important;
  font-size: 0.875em !important;
}

hr.bot_desc_hr {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.fade-enter {
  opacity: 0;
  /* background: red; */
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: all 250ms linear 125ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  background: #fff;
  transition: all 125ms linear;
}

.comment-enter h5 {
  opacity: 0;
  transform: translateY(-100%);
}
.comment-enter-active h5 {
  opacity: 1;
  transform: translateY(0%);
  transition: opacity 700ms, transform 700ms;
}
.comment-exit h5 {
  opacity: 1;
  transform: translateY(0%);
}
.comment-exit-active h5 {
  opacity: 0;
  transform: translateY(100%);
  filter: blur(5px);
  transition: opacity 300ms, transform 300ms;
}

.sequence-fade-enter {
  opacity: 0.8;
  filter: blur(1px);
}
.sequence-fade-enter-active {
  opacity: 1;
  transition: all 500ms ease-in-out;
}
.sequence-fade-exit {
  opacity: 1;
}
.sequence-fade-exit-active {
  opacity: 0.8;
  filter: blur(1px);

  transition: all 500ms ease-in-out;
}

.slide-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.slide-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.slide-enter-active,
.slide-exit-active {
  transition: opacity 200ms, transform 100ms;
}

/* https://codepen.io/sosuke/pen/Pjoqqp */
span.menu {
  width: 18px;
  height: 18px;
  background-size: cover;
}

span.menu.user-config {
  background-image: url('image/user.png');
}
span.menu.robot-config {
  background-image: url('image/robot.png');
  filter: invert(72%) sepia(11%) saturate(6949%) hue-rotate(175deg)
    brightness(98%) contrast(95%);
}
span.menu.notice {
  background-image: url('image/notice.png');
  filter: invert(58%) sepia(61%) saturate(535%) hue-rotate(321deg)
    brightness(103%) contrast(102%);
}
span.menu.manual {
  background-image: url('image/manual.png');
  filter: invert(55%) sepia(16%) saturate(1165%) hue-rotate(190deg)
    brightness(93%) contrast(88%);
}
span.menu.channeltalk {
  background-image: url('image/channeltalk.png');
  filter: invert(89%) sepia(99%) saturate(566%) hue-rotate(328deg)
    brightness(106%) contrast(96%);
}
span.menu.reaction {
  background-image: url('image/reaction.png');
  filter: invert(68%) sepia(44%) saturate(5608%) hue-rotate(305deg)
    brightness(99%) contrast(97%);
}
span.menu.style {
  background-image: url('image/style.png');
  filter: invert(67%) sepia(35%) saturate(697%) hue-rotate(126deg)
    brightness(85%) contrast(92%);
}
span.menu.geo {
  background-image: url('image/geo.png');
  filter: invert(80%) sepia(4%) saturate(3195%) hue-rotate(79deg)
    brightness(89%) contrast(90%);
}
span.menu.tempUnit {
  background-image: url('image/tempUnit.png');
  filter: invert(52%) sepia(85%) saturate(565%) hue-rotate(320deg)
    brightness(118%) contrast(101%);
}
span.menu.disturb {
  background-image: url('image/disturb.png');
  filter: invert(61%) sepia(8%) saturate(901%) hue-rotate(327deg)
    brightness(91%) contrast(85%);
}
span.menu.wifi {
  background-image: url('image/wifi.png');
  filter: invert(61%) sepia(79%) saturate(848%) hue-rotate(160deg)
    brightness(99%) contrast(93%);
}
span.menu.instruction {
  background-image: url('image/instruction.png');
  filter: invert(33%) sepia(51%) saturate(3225%) hue-rotate(184deg)
    brightness(99%) contrast(101%);
}
span.menu.initialize {
  background-image: url('image/initialize.png');
  filter: invert(67%) sepia(55%) saturate(1820%) hue-rotate(249deg)
    brightness(82%) contrast(86%);
}
span.menu.disconnect {
  background-image: url('image/disconnect.png');
  filter: invert(29%) sepia(35%) saturate(4006%) hue-rotate(349deg)
    brightness(85%) contrast(95%);
}
span.menu.updateuser {
  background-image: url('image/updateuser.png');
  filter: invert(55%) sepia(57%) saturate(1807%) hue-rotate(304deg)
    brightness(97%) contrast(101%);
}
span.menu.password {
  background-image: url('image/password.png');
  filter: invert(72%) sepia(34%) saturate(775%) hue-rotate(341deg)
    brightness(102%) contrast(101%);
}
span.menu.signout {
  background-image: url('image/signout.png');
  filter: invert(67%) sepia(35%) saturate(697%) hue-rotate(126deg)
    brightness(85%) contrast(92%);
}
span.menu.withdraw {
  background-image: url('image/withdraw.png');
  filter: invert(81%) sepia(39%) saturate(7037%) hue-rotate(317deg)
    brightness(101%) contrast(102%);
}
span.hibiscus {
  background: center / contain no-repeat url('image/hibiscus.png');
  width: 1.75rem;
  height: 1.75rem;
}

@keyframes seq_3 {
  0% {
    background-image: url('image/sequence/seq_3_0.png');
  }
  10% {
    background-image: url('image/sequence/seq_3_1.png');
  }
  20% {
    background-image: url('image/sequence/seq_3_2.png');
  }
  30% {
    background-image: url('image/sequence/seq_3_3.png');
  }
  40% {
    background-image: url('image/sequence/seq_3_4.png');
  }
  50% {
    background-image: url('image/sequence/seq_3_5.png');
  }
  60% {
    background-image: url('image/sequence/seq_3_4.png');
  }
  70% {
    background-image: url('image/sequence/seq_3_3.png');
  }
  80% {
    background-image: url('image/sequence/seq_3_2.png');
  }
  90% {
    background-image: url('image/sequence/seq_3_1.png');
  }
  100% {
    background-image: url('image/sequence/seq_3_0.png');
  }
}

@keyframes seq_4 {
  0% {
    background-image: url('image/sequence/seq_4_0.png');
  }
  5% {
    background-image: url('image/sequence/seq_4_1.png');
  }
  10% {
    background-image: url('image/sequence/seq_4_2.png');
  }
  15% {
    background-image: url('image/sequence/seq_4_3.png');
  }
  20% {
    background-image: url('image/sequence/seq_4_4.png');
  }
  25% {
    background-image: url('image/sequence/seq_4_5.png');
  }
  30% {
    background-image: url('image/sequence/seq_4_6.png');
  }
  35% {
    background-image: url('image/sequence/seq_4_7.png');
  }
  40% {
    background-image: url('image/sequence/seq_4_8.png');
  }
  45% {
    background-image: url('image/sequence/seq_4_9.png');
  }
  50% {
    background-image: url('image/sequence/seq_4_10.png');
  }
  55% {
    background-image: url('image/sequence/seq_4_9.png');
  }
  60% {
    background-image: url('image/sequence/seq_4_8.png');
  }
  65% {
    background-image: url('image/sequence/seq_4_7.png');
  }
  70% {
    background-image: url('image/sequence/seq_4_6.png');
  }
  75% {
    background-image: url('image/sequence/seq_4_5.png');
  }
  80% {
    background-image: url('image/sequence/seq_4_4.png');
  }
  85% {
    background-image: url('image/sequence/seq_4_3.png');
  }
  90% {
    background-image: url('image/sequence/seq_4_2.png');
  }
  95% {
    background-image: url('image/sequence/seq_4_1.png');
  }
  100% {
    background-image: url('image/sequence/seq_4_0.png');
  }
}

@keyframes seq_5 {
  0% {
    background-image: url('image/sequence/seq_5_0.png');
  }
  5% {
    background-image: url('image/sequence/seq_5_1.png');
  }
  10% {
    background-image: url('image/sequence/seq_5_2.png');
  }
  15% {
    background-image: url('image/sequence/seq_5_3.png');
  }
  20% {
    background-image: url('image/sequence/seq_5_4.png');
  }
  25% {
    background-image: url('image/sequence/seq_5_5.png');
  }
  30% {
    background-image: url('image/sequence/seq_5_6.png');
  }
  35% {
    background-image: url('image/sequence/seq_5_7.png');
  }
  40% {
    background-image: url('image/sequence/seq_5_8.png');
  }
  45% {
    background-image: url('image/sequence/seq_5_9.png');
  }
  50% {
    background-image: url('image/sequence/seq_5_10.png');
  }
  55% {
    background-image: url('image/sequence/seq_5_9.png');
  }
  60% {
    background-image: url('image/sequence/seq_5_8.png');
  }
  65% {
    background-image: url('image/sequence/seq_5_7.png');
  }
  70% {
    background-image: url('image/sequence/seq_5_6.png');
  }
  75% {
    background-image: url('image/sequence/seq_5_5.png');
  }
  80% {
    background-image: url('image/sequence/seq_5_4.png');
  }
  85% {
    background-image: url('image/sequence/seq_5_3.png');
  }
  90% {
    background-image: url('image/sequence/seq_5_2.png');
  }
  95% {
    background-image: url('image/sequence/seq_5_1.png');
  }
  100% {
    background-image: url('image/sequence/seq_5_0.png');
  }
}

@keyframes seq_6 {
  0% {
    background-image: url('image/sequence/seq_6_0.png');
  }
  8.333333333333334% {
    background-image: url('image/sequence/seq_6_1.png');
  }
  16.666666666666668% {
    background-image: url('image/sequence/seq_6_2.png');
  }
  25% {
    background-image: url('image/sequence/seq_6_3.png');
  }
  33.333333333333336% {
    background-image: url('image/sequence/seq_6_4.png');
  }
  41.666666666666664% {
    background-image: url('image/sequence/seq_6_5.png');
  }
  50% {
    background-image: url('image/sequence/seq_6_6.png');
  }
  58.333333333333336% {
    background-image: url('image/sequence/seq_6_5.png');
  }
  66.66666666666667% {
    background-image: url('image/sequence/seq_6_4.png');
  }
  75% {
    background-image: url('image/sequence/seq_6_3.png');
  }
  83.33333333333333% {
    background-image: url('image/sequence/seq_6_2.png');
  }
  91.66666666666667% {
    background-image: url('image/sequence/seq_6_1.png');
  }
  100% {
    background-image: url('image/sequence/seq_6_0.png');
  }
}
@keyframes seq_7 {
  0% {
    background-image: url('image/sequence/seq_7_0.png');
  }
  8.333333333333334% {
    background-image: url('image/sequence/seq_7_1.png');
  }
  16.666666666666668% {
    background-image: url('image/sequence/seq_7_2.png');
  }
  25% {
    background-image: url('image/sequence/seq_7_3.png');
  }
  33.333333333333336% {
    background-image: url('image/sequence/seq_7_4.png');
  }
  41.666666666666664% {
    background-image: url('image/sequence/seq_7_5.png');
  }
  50% {
    background-image: url('image/sequence/seq_7_6.png');
  }
  58.333333333333336% {
    background-image: url('image/sequence/seq_7_5.png');
  }
  66.66666666666667% {
    background-image: url('image/sequence/seq_7_4.png');
  }
  75% {
    background-image: url('image/sequence/seq_7_3.png');
  }
  83.33333333333333% {
    background-image: url('image/sequence/seq_7_2.png');
  }
  91.66666666666667% {
    background-image: url('image/sequence/seq_7_1.png');
  }
  100% {
    background-image: url('image/sequence/seq_7_0.png');
  }
}

@keyframes seq_8 {
  0% {
    background-image: url('image/sequence/seq_8_0.png');
  }
  10% {
    background-image: url('image/sequence/seq_8_1.png');
  }
  20% {
    background-image: url('image/sequence/seq_8_2.png');
  }
  30% {
    background-image: url('image/sequence/seq_8_3.png');
  }
  40% {
    background-image: url('image/sequence/seq_8_4.png');
  }
  50% {
    background-image: url('image/sequence/seq_8_5.png');
  }
  60% {
    background-image: url('image/sequence/seq_8_4.png');
  }
  70% {
    background-image: url('image/sequence/seq_8_3.png');
  }
  80% {
    background-image: url('image/sequence/seq_8_2.png');
  }
  90% {
    background-image: url('image/sequence/seq_8_1.png');
  }
  100% {
    background-image: url('image/sequence/seq_8_0.png');
  }
}

@keyframes seq_9 {
  0% {
    background-image: url('image/sequence/seq_9_0.png');
  }
  5% {
    background-image: url('image/sequence/seq_9_1.png');
  }
  10% {
    background-image: url('image/sequence/seq_9_2.png');
  }
  15% {
    background-image: url('image/sequence/seq_9_3.png');
  }
  20% {
    background-image: url('image/sequence/seq_9_4.png');
  }
  25% {
    background-image: url('image/sequence/seq_9_5.png');
  }
  30% {
    background-image: url('image/sequence/seq_9_6.png');
  }
  35% {
    background-image: url('image/sequence/seq_9_7.png');
  }
  40% {
    background-image: url('image/sequence/seq_9_8.png');
  }
  45% {
    background-image: url('image/sequence/seq_9_7.png');
  }
  50% {
    background-image: url('image/sequence/seq_9_8.png');
  }
  55% {
    background-image: url('image/sequence/seq_9_7.png');
  }
  60% {
    background-image: url('image/sequence/seq_9_8.png');
  }
  65% {
    background-image: url('image/sequence/seq_9_7.png');
  }
  70% {
    background-image: url('image/sequence/seq_9_6.png');
  }
  75% {
    background-image: url('image/sequence/seq_9_5.png');
  }
  80% {
    background-image: url('image/sequence/seq_9_4.png');
  }
  85% {
    background-image: url('image/sequence/seq_9_3.png');
  }
  90% {
    background-image: url('image/sequence/seq_9_2.png');
  }
  95% {
    background-image: url('image/sequence/seq_9_1.png');
  }
  100% {
    background-image: url('image/sequence/seq_9_0.png');
  }
}

.seq_9_0 {
  background-image: url('image/sequence/seq_9_0.png');
}
.seq_9_1 {
  background-image: url('image/sequence/seq_9_1.png');
}
.seq_9_2 {
  background-image: url('image/sequence/seq_9_2.png');
}
.seq_9_3 {
  background-image: url('image/sequence/seq_9_3.png');
}
.seq_9_4 {
  background-image: url('image/sequence/seq_9_4.png');
}
.seq_9_5 {
  background-image: url('image/sequence/seq_9_5.png');
}
.seq_9_6 {
  background-image: url('image/sequence/seq_9_6.png');
}
.seq_9_7 {
  background-image: url('image/sequence/seq_9_7.png');
}
.seq_9_8 {
  background-image: url('image/sequence/seq_9_8.png');
}
.seq_8_0 {
  background-image: url('image/sequence/seq_8_0.png');
}
.seq_8_1 {
  background-image: url('image/sequence/seq_8_1.png');
}
.seq_8_2 {
  background-image: url('image/sequence/seq_8_2.png');
}
.seq_8_3 {
  background-image: url('image/sequence/seq_8_3.png');
}
.seq_8_4 {
  background-image: url('image/sequence/seq_8_4.png');
}
.seq_8_5 {
  background-image: url('image/sequence/seq_8_5.png');
}
.seq_7_0 {
  background-image: url('image/sequence/seq_7_0.png');
}
.seq_7_1 {
  background-image: url('image/sequence/seq_7_1.png');
}
.seq_7_2 {
  background-image: url('image/sequence/seq_7_2.png');
}
.seq_7_3 {
  background-image: url('image/sequence/seq_7_3.png');
}
.seq_7_4 {
  background-image: url('image/sequence/seq_7_4.png');
}
.seq_7_5 {
  background-image: url('image/sequence/seq_7_5.png');
}
.seq_7_6 {
  background-image: url('image/sequence/seq_7_6.png');
}
.seq_6_0 {
  background-image: url('image/sequence/seq_6_0.png');
}
.seq_6_1 {
  background-image: url('image/sequence/seq_6_1.png');
}
.seq_6_2 {
  background-image: url('image/sequence/seq_6_2.png');
}
.seq_6_3 {
  background-image: url('image/sequence/seq_6_3.png');
}
.seq_6_4 {
  background-image: url('image/sequence/seq_6_4.png');
}
.seq_6_5 {
  background-image: url('image/sequence/seq_6_5.png');
}
.seq_6_6 {
  background-image: url('image/sequence/seq_6_6.png');
}
.seq_5_0 {
  background-image: url('image/sequence/seq_5_0.png');
}
.seq_5_1 {
  background-image: url('image/sequence/seq_5_1.png');
}
.seq_5_2 {
  background-image: url('image/sequence/seq_5_2.png');
}
.seq_5_3 {
  background-image: url('image/sequence/seq_5_3.png');
}
.seq_5_4 {
  background-image: url('image/sequence/seq_5_4.png');
}
.seq_5_5 {
  background-image: url('image/sequence/seq_5_5.png');
}
.seq_5_6 {
  background-image: url('image/sequence/seq_5_6.png');
}
.seq_5_7 {
  background-image: url('image/sequence/seq_5_7.png');
}
.seq_5_8 {
  background-image: url('image/sequence/seq_5_8.png');
}
.seq_5_9 {
  background-image: url('image/sequence/seq_5_9.png');
}
.seq_5_10 {
  background-image: url('image/sequence/seq_5_10.png');
}
.seq_4_0 {
  background-image: url('image/sequence/seq_4_0.png');
}
.seq_4_1 {
  background-image: url('image/sequence/seq_4_1.png');
}
.seq_4_2 {
  background-image: url('image/sequence/seq_4_2.png');
}
.seq_4_3 {
  background-image: url('image/sequence/seq_4_3.png');
}
.seq_4_4 {
  background-image: url('image/sequence/seq_4_4.png');
}
.seq_4_5 {
  background-image: url('image/sequence/seq_4_5.png');
}
.seq_4_6 {
  background-image: url('image/sequence/seq_4_6.png');
}
.seq_4_7 {
  background-image: url('image/sequence/seq_4_7.png');
}
.seq_4_8 {
  background-image: url('image/sequence/seq_4_8.png');
}
.seq_4_9 {
  background-image: url('image/sequence/seq_4_9.png');
}
.seq_4_10 {
  background-image: url('image/sequence/seq_4_10.png');
}
.seq_3_0 {
  background-image: url('image/sequence/seq_3_0.png');
}
.seq_3_1 {
  background-image: url('image/sequence/seq_3_1.png');
}
.seq_3_2 {
  background-image: url('image/sequence/seq_3_2.png');
}
.seq_3_3 {
  background-image: url('image/sequence/seq_3_3.png');
}
.seq_3_4 {
  background-image: url('image/sequence/seq_3_4.png');
}
.seq_3_5 {
  background-image: url('image/sequence/seq_3_5.png');
}

.slide-right {
  width: 100%;
  overflow: hidden;
}

.slide-right h5 {
  white-space: nowrap;
  animation: 37s linear slide-right 0s infinite forwards;
  transform: translateX(0%);
}

@keyframes slide-right {
  0% {
    transfrom: translateX(0%);
  }
  50% {
    transform: translateX(-80%);
  }
  100% {
    transform: translateX(0%);
  }
}

.popup_231129 {
  width: 100%;
  height: 100%;
  background-image: url('image/popup_app.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: white;
}

.ui.dimmer > .content:has(> .popup_231129),
.ui.dimmer > .content:has(> .guide) {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.guide {
  position: relative;
  width: 100%;
  height: 100%;
}

.guide::after {
  content: '\f057 가이드 닫기';
  width: 100%;
  height: auto;
  display: inline-block;
  position: absolute;
  top: 33%;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.85);
  font-size: x-large;
  font-family: 'Icons';
}

.guide .main_guide_top {
  width: 100%;
  height: 50%;
  background-image: url('image/overlay_top_bigger.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

.guide .main_guide_bottom {
  width: 100%;
  height: 50%;
  background-image: url('image/overlay_bottom_bigger.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

@fontName: 'Comfortaa';
@headerFont : 'Comfortaa', 'Noto Sans SC', 'Nanum Gothic', sans-serif;
@pageFont: 'Comfortaa', 'Noto Sans SC', 'Nanum Gothic', sans-serif;
@googleFontName: 'Comfortaa';

body {
  background-color: rgb(255, 255, 255);
  font-size: 16px;
}

html {
  font-size: 16px;
}